import React from "react";
import "./Gruppe25.css";

function Gruppe25(props) {
  const { pfad111, pfad112, pfad113, pfad114, pfad115, className } = props;

  return (
    <div className={`gruppe-25 ${className || ""}`}>
      <img className="pfad-111" src={pfad111} alt="Pfad 111" />
      <img className="pfad-112" src={pfad112} alt="Pfad 112" />
      <img className="pfad-113" src={pfad113} alt="Pfad 113" />
      <img className="pfad-11-1" src={pfad114} alt="Pfad 114" />
      <img className="pfad-1-3" src={pfad115} alt="Pfad 115" />
    </div>
  );
}

export default Gruppe25;
