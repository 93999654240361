import React from "react";
import Gruppe25 from "../Gruppe25";
import Gruppe29 from "../Gruppe29";
import Gruppe33 from "../Gruppe33";
import "./BenutzerdefiniertesFormat1.css";

function BenutzerdefiniertesFormat1(props) {
  const {
    ellipse5,
    ellipse3,
    telegram1,
    discord,
    telegram2,
    about,
    team,
    claim,
    connect,
    ellipse1,
    ellipse2,
    all1,
    gruppe18,
    pfad1,
    pfad2,
    pfad3,
    pfad4,
    pfad5,
    pfad6,
    pfad7,
    pfad8,
    pfad9,
    pfad10,
    pfad11,
    pfad12,
    pfad13,
    pfad14,
    pfad15,
    pfad16,
    pfad17,
    pfad18,
    pfad19,
    pfad20,
    pfad21,
    pfad22,
    pfad23,
    pfad24,
    pfad25,
    pfad26,
    pfad27,
    pfad28,
    pfad29,
    pfad30,
    pfad31,
    pfad32,
    pfad33,
    pfad34,
    pfad35,
    pfad36,
    pfad37,
    pfad38,
    pfad39,
    pfad40,
    pfad41,
    pfad42,
    pfad43,
    pfad44,
    pfad45,
    pfad46,
    pfad47,
    pfad48,
    pfad49,
    pfad50,
    pfad51,
    pfad52,
    pfad53,
    pfad54,
    pfad55,
    pfad56,
    pfad57,
    pfad58,
    pfad59,
    pfad60,
    pfad61,
    pfad62,
    pfad63,
    pfad64,
    pfad65,
    pfad66,
    pfad67,
    pfad68,
    pfad69,
    pfad70,
    pfad71,
    pfad72,
    pfad73,
    pfad74,
    pfad75,
    pfad76,
    pfad77,
    pfad78,
    pfad79,
    pfad80,
    pfad81,
    pfad82,
    pfad83,
    pfad84,
    pfad85,
    pfad86,
    pfad87,
    pfad88,
    pfad89,
    pfad90,
    pfad91,
    pfad92,
    pfad93,
    pfad94,
    pfad95,
    pfad96,
    pfad97,
    pfad98,
    pfad99,
    pfad100,
    pfad101,
    pfad102,
    pfad103,
    pfad104,
    pfad105,
    pfad106,
    pfad107,
    pfad108,
    pfad109,
    pfad110,
    pfad121,
    pfad122,
    pfad123,
    pfad124,
    pfad125,
    pfad126,
    pfad127,
    pfad128,
    pfad129,
    pfad130,
    gruppe32,
    logoUpdated,
    place,
    roadmap,
    howTo1,
    instagram,
    gruppe48,
    overlapGroup1,
    pfad181,
    pfad182,
    pfad183,
    pfad184,
    pfad187,
    overlapGroup,
    pfad186,
    pfad188,
    pfad189,
    pfad190,
    pfad191,
    pfad192,
    pfad193,
    pfad194,
    gruppe46,
    pfad196,
    gruppe47,
    pfad198,
    pfad199,
    pfad200,
    pfad201,
    pfad202,
    pfad203,
    pfad204,
    pfad205,
    pfad206,
    pfad207,
    gruppe52,
    overlapGroup2,
    pfad210,
    pfad211,
    pfad212,
    pfad213,
    pfad214,
    pfad215,
    pfad216,
    pfad217,
    pfad218,
    pfad219,
    pfad220,
    pfad221,
    pfad222,
    pfad223,
    pfad224,
    gruppe50,
    pfad226,
    gruppe51,
    pfad228,
    pfad229,
    pfad230,
    pfad231,
    pfad232,
    pfad233,
    pfad234,
    pfad235,
    pfad236,
    gruppe53,
    pfad238,
    pfad239,
    pfad240,
    pfad241,
    pfad242,
    pfad243,
    pfad244,
    pfad245,
    pfad246,
    pfad247,
    pfad248,
    pfad249,
    pfad250,
    pfad251,
    pfad252,
    pfad253,
    pfad254,
    pfad255,
    pfad256,
    pfad257,
    pfad258,
    pfad259,
    penguCombinesADef1,
    gruppe411,
    pfad1621,
    pfad1631,
    pfad1641,
    pfad1651,
    pfad1661,
    pfad1671,
    pfad1681,
    pfad1691,
    pfad1701,
    pfad1711,
    pfad1721,
    pfad1731,
    pfad1741,
    pfad1751,
    pfad1761,
    pfad1771,
    gruppe412,
    pfad1622,
    pfad1632,
    pfad1642,
    pfad1652,
    pfad1662,
    pfad1672,
    pfad1682,
    pfad1692,
    pfad1702,
    pfad1712,
    pfad1722,
    pfad1732,
    pfad1742,
    pfad1752,
    pfad1762,
    pfad1772,
    buy,
    howTo2,
    ellipse4,
    bgPengu2Back,
    bgPengu2Aurora,
    penguCombinesADef2,
    uI48c,
    theVisionOfPengu,
    x015OfTheTransac1,
    x015OfTheTransac2,
    x010OfTheTransac1,
    x010OfTheTransac2,
    igloo2Back,
    igloo2Rocket,
    igloo2Front,
    igloo1,
    igloo1Glow,
    igloo2Fire,
    igloo2Sign,
    igloo2Glow,
    all2,
    bgPengu3Bg,
    bgPengu3Iceberg1,
    bgPengu3Iceberg11,
    bgPengu3Iceberg2,
    bgPengu3Iceberg21,
    bgPengu3Iceberg3,
    bgPengu3Iceberg4,
    bgPengu3Iceberg5,
    bgPengu3Iceberg51,
    bgPengu3Iceberg52,
    bgPengu3Iceberg6,
    bgPengu3Iceberg61,
    gruppe251Props,
    gruppe252Props,
    gruppe291Props,
    gruppe292Props,
    gruppe331Props,
    gruppe332Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="benutzerdefiniertes-format-1 screen">
        <div className="overlap-group9">
          <div className="overlap-group8">
            <div className="rechteck-21">
              <video
                src="https://cdn.animaapp.com/projects/6332323a583fb3c075950015/files/adobestock_299879654_video_hd_preview.mov"
                loop
                autoplay="autoplay"
                playsinline
                muted
              ></video>
            </div>
            <div className="rechteck-22"></div>
            <img className="ellipse-5" src={ellipse5} alt="Ellipse 5" />
            <img className="ellipse-3" src={ellipse3} alt="Ellipse 3" />
            <div className="rechteck-18"></div>
            <img className="telegram" src={telegram1} alt="telegram" />
            <img className="discord" src={discord} alt="discord" />
            <img className="telegram-1" src={telegram2} alt="telegram" />
            <div className="about opensans-normal-white-17px">{about}</div>
            <div className="team opensans-normal-white-17px">{team}</div>
            <div className="rechteck-1"></div>
            <div className="rechteck-2"></div>
            <div className="claim opensans-normal-white-17px">{claim}</div>
            <div className="connect opensans-normal-white-17px">{connect}</div>
            <img className="ellipse-1" src={ellipse1} alt="Ellipse 1" />
            <img className="ellipse-2" src={ellipse2} alt="Ellipse 2" />
            <img className="all" src={all1} alt="All " />
            <div className="unbenannt-2">
              <div className="ebene-1">
                <div className="gruppe-37">
                  <div className="overlap-group3">
                    <div className="gruppe-18" style={{ backgroundImage: `url(${gruppe18})` }}>
                      <div className="gruppe-17">
                        <div className="gruppe-container">
                          <div className="gruppe-1">
                            <img className="pfad-1-2" src={pfad1} alt="Pfad 1" />
                            <img className="pfad-2-1" src={pfad2} alt="Pfad 2" />
                            <img className="pfad" src={pfad3} alt="Pfad 3" />
                            <img className="pfad-4-2" src={pfad4} alt="Pfad 4" />
                            <img className="pfad-1" src={pfad5} alt="Pfad 5" />
                          </div>
                          <div className="gruppe-2">
                            <img className="pfad-6-2" src={pfad6} alt="Pfad 6" />
                            <img className="pfad-7-1" src={pfad7} alt="Pfad 7" />
                            <img className="pfad" src={pfad8} alt="Pfad 8" />
                            <img className="pfad-2" src={pfad9} alt="Pfad 9" />
                            <img className="pfad-1" src={pfad10} alt="Pfad 10" />
                          </div>
                          <div className="gruppe-3">
                            <img className="pfad-3" src={pfad11} alt="Pfad 11" />
                            <img className="pfad-4" src={pfad12} alt="Pfad 12" />
                            <img className="pfad" src={pfad13} alt="Pfad 13" />
                            <img className="pfad-2" src={pfad14} alt="Pfad 14" />
                            <img className="pfad-1" src={pfad15} alt="Pfad 15" />
                          </div>
                          <div className="gruppe-4">
                            <img className="pfad-16-1" src={pfad16} alt="Pfad 16" />
                            <img className="pfad-17-1" src={pfad17} alt="Pfad 17" />
                            <img className="pfad-5" src={pfad18} alt="Pfad 18" />
                            <img className="pfad-2" src={pfad19} alt="Pfad 19" />
                            <img className="pfad-6" src={pfad20} alt="Pfad 20" />
                          </div>
                          <div className="gruppe-5">
                            <img className="pfad-3" src={pfad21} alt="Pfad 21" />
                            <img className="pfad-4" src={pfad22} alt="Pfad 22" />
                            <img className="pfad-5" src={pfad23} alt="Pfad 23" />
                            <img className="pfad-24-1" src={pfad24} alt="Pfad 24" />
                            <img className="pfad-6" src={pfad25} alt="Pfad 25" />
                          </div>
                          <div className="gruppe-6">
                            <img className="pfad-7" src={pfad26} alt="Pfad 26" />
                            <img className="pfad-8" src={pfad27} alt="Pfad 27" />
                            <img className="pfad-5" src={pfad28} alt="Pfad 28" />
                            <img className="pfad-9" src={pfad29} alt="Pfad 29" />
                            <img className="pfad-6" src={pfad30} alt="Pfad 30" />
                          </div>
                          <div className="gruppe-7">
                            <img className="pfad-7" src={pfad31} alt="Pfad 31" />
                            <img className="pfad-8" src={pfad32} alt="Pfad 32" />
                            <img className="pfad-3-1" src={pfad33} alt="Pfad 33" />
                            <img className="pfad-9" src={pfad34} alt="Pfad 34" />
                            <img className="pfad-10" src={pfad35} alt="Pfad 35" />
                          </div>
                          <div className="gruppe-8">
                            <img className="pfad-7" src={pfad36} alt="Pfad 36" />
                            <img className="pfad-37" src={pfad37} alt="Pfad 37" />
                            <img className="pfad-3-1" src={pfad38} alt="Pfad 38" />
                            <img className="pfad-11" src={pfad39} alt="Pfad 39" />
                            <img className="pfad-10" src={pfad40} alt="Pfad 40" />
                          </div>
                          <div className="gruppe-9">
                            <img className="pfad-4-1" src={pfad41} alt="Pfad 41" />
                            <img className="pfad-8" src={pfad42} alt="Pfad 42" />
                            <img className="pfad-12" src={pfad43} alt="Pfad 43" />
                            <img className="pfad-11" src={pfad44} alt="Pfad 44" />
                            <img className="pfad-13" src={pfad45} alt="Pfad 45" />
                          </div>
                          <div className="gruppe-10">
                            <img className="pfad-4-1" src={pfad46} alt="Pfad 46" />
                            <img className="pfad-14" src={pfad47} alt="Pfad 47" />
                            <img className="pfad-12" src={pfad48} alt="Pfad 48" />
                            <img className="pfad-49" src={pfad49} alt="Pfad 49" />
                            <img className="pfad-13" src={pfad50} alt="Pfad 50" />
                          </div>
                          <div className="gruppe-11">
                            <img className="pfad-15" src={pfad51} alt="Pfad 51" />
                            <img className="pfad-52" src={pfad52} alt="Pfad 52" />
                            <img className="pfad-12" src={pfad53} alt="Pfad 53" />
                            <img className="pfad-5-1" src={pfad54} alt="Pfad 54" />
                            <img className="pfad-13" src={pfad55} alt="Pfad 55" />
                          </div>
                          <div className="gruppe-12">
                            <img className="pfad-15" src={pfad56} alt="Pfad 56" />
                            <img className="pfad-14" src={pfad57} alt="Pfad 57" />
                            <img className="pfad-16" src={pfad58} alt="Pfad 58" />
                            <img className="pfad-5-1" src={pfad59} alt="Pfad 59" />
                            <img className="pfad-17" src={pfad60} alt="Pfad 60" />
                          </div>
                          <div className="gruppe-13">
                            <img className="pfad-15" src={pfad61} alt="Pfad 61" />
                            <img className="pfad-18" src={pfad62} alt="Pfad 62" />
                            <img className="pfad-16" src={pfad63} alt="Pfad 63" />
                            <img className="pfad-6-1" src={pfad64} alt="Pfad 64" />
                            <img className="pfad-17" src={pfad65} alt="Pfad 65" />
                          </div>
                          <div className="gruppe-14">
                            <img className="pfad-19" src={pfad66} alt="Pfad 66" />
                            <img className="pfad-18" src={pfad67} alt="Pfad 67" />
                            <img className="pfad-20" src={pfad68} alt="Pfad 68" />
                            <img className="pfad-6-1" src={pfad69} alt="Pfad 69" />
                            <img className="pfad-17" src={pfad70} alt="Pfad 70" />
                          </div>
                          <div className="gruppe-15">
                            <img className="pfad-19" src={pfad71} alt="Pfad 71" />
                            <img className="pfad-72" src={pfad72} alt="Pfad 72" />
                            <img className="pfad-20" src={pfad73} alt="Pfad 73" />
                            <img className="pfad-74" src={pfad74} alt="Pfad 74" />
                            <img className="pfad-21" src={pfad75} alt="Pfad 75" />
                          </div>
                          <div className="gruppe-16">
                            <img className="pfad-76" src={pfad76} alt="Pfad 76" />
                            <img className="pfad-18" src={pfad77} alt="Pfad 77" />
                            <img className="pfad-20" src={pfad78} alt="Pfad 78" />
                            <img className="pfad-79" src={pfad79} alt="Pfad 79" />
                            <img className="pfad-21" src={pfad80} alt="Pfad 80" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="gruppe-container-1">
                      <div className="gruppe-19">
                        <img className="pfad-22" src={pfad81} alt="Pfad 81" />
                        <img className="pfad-82" src={pfad82} alt="Pfad 82" />
                        <img className="pfad-23" src={pfad83} alt="Pfad 83" />
                        <img className="pfad-8-1" src={pfad84} alt="Pfad 84" />
                        <img className="pfad-85" src={pfad85} alt="Pfad 85" />
                      </div>
                      <div className="gruppe-20">
                        <img className="pfad-24" src={pfad86} alt="Pfad 86" />
                        <img className="pfad-87" src={pfad87} alt="Pfad 87" />
                        <img className="pfad-25" src={pfad88} alt="Pfad 88" />
                        <img className="pfad-8-1" src={pfad89} alt="Pfad 89" />
                        <img className="pfad-26" src={pfad90} alt="Pfad 90" />
                      </div>
                      <div className="gruppe-21">
                        <img className="pfad-91" src={pfad91} alt="Pfad 91" />
                        <img className="pfad-92" src={pfad92} alt="Pfad 92" />
                        <img className="pfad-27" src={pfad93} alt="Pfad 93" />
                        <img className="pfad-94" src={pfad94} alt="Pfad 94" />
                        <img className="pfad-26" src={pfad95} alt="Pfad 95" />
                      </div>
                      <div className="gruppe-22">
                        <img className="pfad-22" src={pfad96} alt="Pfad 96" />
                        <img className="pfad-97" src={pfad97} alt="Pfad 97" />
                        <img className="pfad-23" src={pfad98} alt="Pfad 98" />
                        <img className="pfad-28" src={pfad99} alt="Pfad 99" />
                        <img className="pfad-26" src={pfad100} alt="Pfad 100" />
                      </div>
                      <div className="gruppe-23">
                        <img className="pfad-24" src={pfad101} alt="Pfad 101" />
                        <img className="pfad-102" src={pfad102} alt="Pfad 102" />
                        <img className="pfad-25" src={pfad103} alt="Pfad 103" />
                        <img className="pfad-28" src={pfad104} alt="Pfad 104" />
                        <img className="pfad-26" src={pfad105} alt="Pfad 105" />
                      </div>
                      <div className="gruppe-24">
                        <img className="pfad-106" src={pfad106} alt="Pfad 106" />
                        <img className="pfad-107" src={pfad107} alt="Pfad 107" />
                        <img className="pfad-27" src={pfad108} alt="Pfad 108" />
                        <img className="pfad-109" src={pfad109} alt="Pfad 109" />
                        <img className="pfad-26" src={pfad110} alt="Pfad 110" />
                      </div>
                      <Gruppe25
                        pfad111={gruppe251Props.pfad111}
                        pfad112={gruppe251Props.pfad112}
                        pfad113={gruppe251Props.pfad113}
                        pfad114={gruppe251Props.pfad114}
                        pfad115={gruppe251Props.pfad115}
                      />
                      <Gruppe25
                        pfad111={gruppe252Props.pfad111}
                        pfad112={gruppe252Props.pfad112}
                        pfad113={gruppe252Props.pfad113}
                        pfad114={gruppe252Props.pfad114}
                        pfad115={gruppe252Props.pfad115}
                        className={gruppe252Props.className}
                      />
                      <div className="gruppe-27">
                        <img className="pfad-12-1" src={pfad121} alt="Pfad 121" />
                        <img className="pfad-122" src={pfad122} alt="Pfad 122" />
                        <img className="pfad-12-2" src={pfad123} alt="Pfad 123" />
                        <img className="pfad-12-3" src={pfad124} alt="Pfad 124" />
                        <img className="pfad-1-1" src={pfad125} alt="Pfad 125" />
                      </div>
                      <div className="gruppe-28">
                        <img className="pfad-12-1" src={pfad126} alt="Pfad 126" />
                        <img className="pfad-127" src={pfad127} alt="Pfad 127" />
                        <img className="pfad-12-2" src={pfad128} alt="Pfad 128" />
                        <img className="pfad-12-3" src={pfad129} alt="Pfad 129" />
                        <img className="pfad-1-1" src={pfad130} alt="Pfad 130" />
                      </div>
                      <Gruppe29
                        pfad131={gruppe291Props.pfad131}
                        pfad132={gruppe291Props.pfad132}
                        pfad133={gruppe291Props.pfad133}
                        pfad134={gruppe291Props.pfad134}
                        pfad135={gruppe291Props.pfad135}
                      />
                    </div>
                    <div className="gruppe-32" style={{ backgroundImage: `url(${gruppe32})` }}>
                      <Gruppe29
                        pfad131={gruppe292Props.pfad131}
                        pfad132={gruppe292Props.pfad132}
                        pfad133={gruppe292Props.pfad133}
                        pfad134={gruppe292Props.pfad134}
                        pfad135={gruppe292Props.pfad135}
                        className={gruppe292Props.className}
                      />
                    </div>
                    <div className="gruppe-container-2">
                      <Gruppe33
                        pfad141={gruppe331Props.pfad141}
                        pfad142={gruppe331Props.pfad142}
                        pfad143={gruppe331Props.pfad143}
                        pfad144={gruppe331Props.pfad144}
                        pfad145={gruppe331Props.pfad145}
                      />
                      <div className="gruppe-35">
                        <Gruppe33
                          pfad141={gruppe332Props.pfad141}
                          pfad142={gruppe332Props.pfad142}
                          pfad143={gruppe332Props.pfad143}
                          pfad144={gruppe332Props.pfad144}
                          pfad145={gruppe332Props.pfad145}
                          className={gruppe332Props.className}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="logo-updated" src={logoUpdated} alt="logo updated" />
            <div className="place opensans-normal-white-17px">{place}</div>
            <div className="roadmap opensans-normal-white-17px">{roadmap}</div>
            <div className="how-to opensans-normal-white-17px">{howTo1}</div>
            <img className="icon-instagram" src={instagram} alt="icon-instagram" />
            <div className="overlap-group5">
              <div className="gruppe-48" style={{ backgroundImage: `url(${gruppe48})` }}>
                <div className="overlap-group1" style={{ backgroundImage: `url(${overlapGroup1})` }}>
                  <img className="pfad-181" src={pfad181} alt="Pfad 181" />
                  <img className="pfad-182" src={pfad182} alt="Pfad 182" />
                  <img className="pfad-183" src={pfad183} alt="Pfad 183" />
                  <img className="pfad-184" src={pfad184} alt="Pfad 184" />
                  <div className="gruppe-45">
                    <img className="pfad-187" src={pfad187} alt="Pfad 187" />
                    <div className="overlap-group" style={{ backgroundImage: `url(${overlapGroup})` }}>
                      <img className="pfad-186" src={pfad186} alt="Pfad 186" />
                    </div>
                  </div>
                  <img className="pfad-188" src={pfad188} alt="Pfad 188" />
                  <img className="pfad-189" src={pfad189} alt="Pfad 189" />
                  <img className="pfad-190" src={pfad190} alt="Pfad 190" />
                  <img className="pfad-191" src={pfad191} alt="Pfad 191" />
                  <img className="pfad-192" src={pfad192} alt="Pfad 192" />
                  <img className="pfad-193" src={pfad193} alt="Pfad 193" />
                  <img className="pfad-194" src={pfad194} alt="Pfad 194" />
                  <div className="gruppe-46" style={{ backgroundImage: `url(${gruppe46})` }}></div>
                  <img className="pfad-196" src={pfad196} alt="Pfad 196" />
                  <div className="gruppe-47" style={{ backgroundImage: `url(${gruppe47})` }}></div>
                  <img className="pfad-198" src={pfad198} alt="Pfad 198" />
                  <img className="pfad-199" src={pfad199} alt="Pfad 199" />
                  <img className="pfad-200" src={pfad200} alt="Pfad 200" />
                  <img className="pfad-201" src={pfad201} alt="Pfad 201" />
                  <img className="pfad-202" src={pfad202} alt="Pfad 202" />
                  <img className="pfad-203" src={pfad203} alt="Pfad 203" />
                  <img className="pfad-204" src={pfad204} alt="Pfad 204" />
                  <img className="pfad-205" src={pfad205} alt="Pfad 205" />
                  <img className="pfad-206" src={pfad206} alt="Pfad 206" />
                  <img className="pfad-207" src={pfad207} alt="Pfad 207" />
                </div>
              </div>
              <div className="gruppe-52" style={{ backgroundImage: `url(${gruppe52})` }}>
                <div className="overlap-group2" style={{ backgroundImage: `url(${overlapGroup2})` }}>
                  <img className="pfad-210" src={pfad210} alt="Pfad 210" />
                  <img className="pfad-211" src={pfad211} alt="Pfad 211" />
                  <img className="pfad-212" src={pfad212} alt="Pfad 212" />
                  <img className="pfad-213" src={pfad213} alt="Pfad 213" />
                  <img className="pfad-214" src={pfad214} alt="Pfad 214" />
                  <div className="pfad-container">
                    <img className="pfad-215" src={pfad215} alt="Pfad 215" />
                    <img className="pfad-216" src={pfad216} alt="Pfad 216" />
                    <img className="pfad-217" src={pfad217} alt="Pfad 217" />
                  </div>
                  <img className="pfad-218" src={pfad218} alt="Pfad 218" />
                  <img className="pfad-219" src={pfad219} alt="Pfad 219" />
                  <img className="pfad-220" src={pfad220} alt="Pfad 220" />
                  <img className="pfad-221" src={pfad221} alt="Pfad 221" />
                  <img className="pfad-222" src={pfad222} alt="Pfad 222" />
                  <img className="pfad-223" src={pfad223} alt="Pfad 223" />
                  <img className="pfad-224" src={pfad224} alt="Pfad 224" />
                  <div className="gruppe-50" style={{ backgroundImage: `url(${gruppe50})` }}></div>
                  <img className="pfad-226" src={pfad226} alt="Pfad 226" />
                  <div className="gruppe-51" style={{ backgroundImage: `url(${gruppe51})` }}></div>
                  <img className="pfad-228" src={pfad228} alt="Pfad 228" />
                  <img className="pfad-229" src={pfad229} alt="Pfad 229" />
                  <img className="pfad-230" src={pfad230} alt="Pfad 230" />
                  <img className="pfad-231" src={pfad231} alt="Pfad 231" />
                  <img className="pfad-232" src={pfad232} alt="Pfad 232" />
                  <img className="pfad-233" src={pfad233} alt="Pfad 233" />
                  <img className="pfad-234" src={pfad234} alt="Pfad 234" />
                  <img className="pfad-235" src={pfad235} alt="Pfad 235" />
                  <img className="pfad-236" src={pfad236} alt="Pfad 236" />
                </div>
              </div>
              <div className="overlap-group3-1">
                <div className="gruppe-53" style={{ backgroundImage: `url(${gruppe53})` }}>
                  <img className="pfad-238" src={pfad238} alt="Pfad 238" />
                </div>
                <img className="pfad-239" src={pfad239} alt="Pfad 239" />
                <img className="pfad-240" src={pfad240} alt="Pfad 240" />
                <img className="pfad-241" src={pfad241} alt="Pfad 241" />
                <img className="pfad-242" src={pfad242} alt="Pfad 242" />
                <img className="pfad-243" src={pfad243} alt="Pfad 243" />
                <img className="pfad-244" src={pfad244} alt="Pfad 244" />
                <img className="pfad-245" src={pfad245} alt="Pfad 245" />
                <img className="pfad-246" src={pfad246} alt="Pfad 246" />
                <img className="pfad-247" src={pfad247} alt="Pfad 247" />
                <img className="pfad-248" src={pfad248} alt="Pfad 248" />
                <img className="pfad-249" src={pfad249} alt="Pfad 249" />
                <img className="pfad-250" src={pfad250} alt="Pfad 250" />
                <img className="pfad-251" src={pfad251} alt="Pfad 251" />
                <img className="pfad-252" src={pfad252} alt="Pfad 252" />
                <img className="pfad-253" src={pfad253} alt="Pfad 253" />
                <img className="pfad-254" src={pfad254} alt="Pfad 254" />
              </div>
              <div className="pfad-container-1">
                <img className="pfad-255" src={pfad255} alt="Pfad 255" />
                <img className="pfad-256" src={pfad256} alt="Pfad 256" />
                <img className="pfad-257" src={pfad257} alt="Pfad 257" />
                <img className="pfad-258" src={pfad258} alt="Pfad 258" />
                <img className="pfad-259" src={pfad259} alt="Pfad 259" />
              </div>
            </div>
            <div className="pengu-combines-a-def opensans-normal-black-17px">{penguCombinesADef1}</div>
            <div className="overlap-group6">
              <div className="gruppe-41" style={{ backgroundImage: `url(${gruppe411})` }}>
                <div className="rechteck-20"></div>
              </div>
              <img className="pfad-162" src={pfad1621} alt="Pfad 162" />
              <img className="pfad-163" src={pfad1631} alt="Pfad 163" />
              <img className="pfad-164" src={pfad1641} alt="Pfad 164" />
              <img className="pfad-165" src={pfad1651} alt="Pfad 165" />
              <img className="pfad-166" src={pfad1661} alt="Pfad 166" />
              <img className="pfad-167" src={pfad1671} alt="Pfad 167" />
              <img className="pfad-168" src={pfad1681} alt="Pfad 168" />
              <img className="pfad-169" src={pfad1691} alt="Pfad 169" />
              <img className="pfad-170" src={pfad1701} alt="Pfad 170" />
              <img className="pfad-171" src={pfad1711} alt="Pfad 171" />
              <img className="pfad-172" src={pfad1721} alt="Pfad 172" />
              <img className="pfad-173" src={pfad1731} alt="Pfad 173" />
              <img className="pfad-174" src={pfad1741} alt="Pfad 174" />
              <img className="pfad-175" src={pfad1751} alt="Pfad 175" />
              <img className="pfad-176" src={pfad1761} alt="Pfad 176" />
              <img className="pfad-177" src={pfad1771} alt="Pfad 177" />
            </div>
            <div className="overlap-group7">
              <div className="gruppe-41" style={{ backgroundImage: `url(${gruppe412})` }}>
                <div className="rechteck-20"></div>
              </div>
              <img className="pfad-162" src={pfad1622} alt="Pfad 162" />
              <img className="pfad-163" src={pfad1632} alt="Pfad 163" />
              <img className="pfad-164" src={pfad1642} alt="Pfad 164" />
              <img className="pfad-165" src={pfad1652} alt="Pfad 165" />
              <img className="pfad-166" src={pfad1662} alt="Pfad 166" />
              <img className="pfad-167" src={pfad1672} alt="Pfad 167" />
              <img className="pfad-168" src={pfad1682} alt="Pfad 168" />
              <img className="pfad-169" src={pfad1692} alt="Pfad 169" />
              <img className="pfad-170" src={pfad1702} alt="Pfad 170" />
              <img className="pfad-171" src={pfad1712} alt="Pfad 171" />
              <img className="pfad-172" src={pfad1722} alt="Pfad 172" />
              <img className="pfad-173" src={pfad1732} alt="Pfad 173" />
              <img className="pfad-174" src={pfad1742} alt="Pfad 174" />
              <img className="pfad-175" src={pfad1752} alt="Pfad 175" />
              <img className="pfad-176" src={pfad1762} alt="Pfad 176" />
              <img className="pfad-177" src={pfad1772} alt="Pfad 177" />
            </div>
            <div className="buy segoeuivariabletext-bold-white-25px">{buy}</div>
            <div className="how-to-1 segoeuivariabletext-bold-white-25px">{howTo2}</div>
            <img className="ellipse-4" src={ellipse4} alt="Ellipse 4" />
          </div>
          <img className="overlap-group9-item" src={bgPengu2Back} alt="BG pengu 2back" />
          <img className="bg-pengu-2-aurora" src={bgPengu2Aurora} alt="BG pengu 2 aurora " />
          <div className="rechteck-23"></div>
          <div className="pengu-combines-a-def-1 opensans-normal-white-17px">{penguCombinesADef2}</div>
          <div className="x5 opensans-normal-white-17px">{uI48c}</div>
          <h1 className="title">{theVisionOfPengu}</h1>
          <div className="rechteck-24"></div>
          <div className="rechteck-25"></div>
          <div className="rechteck-26"></div>
          <div className="rechteck-27"></div>
          <div className="x015-of-the-transac opensans-normal-black-17px">{x015OfTheTransac1}</div>
          <div className="x015-of-the-transac-1 opensans-normal-black-17px">{x015OfTheTransac2}</div>
          <div className="x010-of-the-transac opensans-normal-black-17px">{x010OfTheTransac1}</div>
          <div className="x010-of-the-transac-1 opensans-normal-black-17px">{x010OfTheTransac2}</div>
          <img className="overlap-group9-item" src={igloo2Back} alt="igloo 2 back" />
          <img className="overlap-group9-item" src={igloo2Rocket} alt="igloo 2 rocket" />
          <img className="overlap-group9-item" src={igloo2Front} alt="igloo 2 front" />
          <img className="igloo-1" src={igloo1} alt="igloo 1" />
          <img className="igloo-1" src={igloo1Glow} alt="igloo 1 glow" />
          <img className="overlap-group9-item" src={igloo2Fire} alt="igloo 2 fire" />
          <img className="overlap-group9-item" src={igloo2Sign} alt="igloo 2 sign" />
          <img className="overlap-group9-item" src={igloo2Glow} alt="igloo 2 glow" />
          <img className="all-1" src={all2} alt="All" />
          <img className="bg-pengu-3-bg" src={bgPengu3Bg} alt="BG pengu 3 BG" />
          <img className="bg-pengu-3-iceberg" src={bgPengu3Iceberg1} alt="BG pengu 3 Iceberg 1" />
          <img className="bg-pengu-3-iceberg" src={bgPengu3Iceberg11} alt="BG pengu 3 Iceberg 1.1" />
          <img className="bg-pengu-3-iceberg" src={bgPengu3Iceberg2} alt="BG pengu 3 Iceberg 2" />
          <img className="bg-pengu-3-iceberg" src={bgPengu3Iceberg21} alt="BG pengu 3 Iceberg 2.1" />
          <img className="bg-pengu-3-iceberg" src={bgPengu3Iceberg3} alt="BG pengu 3 Iceberg 3" />
          <img className="bg-pengu-3-iceberg" src={bgPengu3Iceberg4} alt="BG pengu 3 Iceberg 4" />
          <img className="bg-pengu-3-iceberg" src={bgPengu3Iceberg5} alt="BG pengu 3 Iceberg 5" />
          <img className="bg-pengu-3-iceberg" src={bgPengu3Iceberg51} alt="BG pengu 3 Iceberg 5.1" />
          <img className="bg-pengu-3-iceberg" src={bgPengu3Iceberg52} alt="BG pengu 3 Iceberg 5.2" />
          <img className="bg-pengu-3-iceberg" src={bgPengu3Iceberg6} alt="BG pengu 3 Iceberg 6" />
          <img className="bg-pengu-3-iceberg" src={bgPengu3Iceberg61} alt="BG pengu 3 Iceberg 6.1" />
        </div>
      </div>
    </div>
  );
}

export default BenutzerdefiniertesFormat1;
