import React from "react";
import "./Gruppe33.css";

function Gruppe33(props) {
  const { pfad141, pfad142, pfad143, pfad144, pfad145, className } = props;

  return (
    <div className={`gruppe-33 ${className || ""}`}>
      <img className="pfad-14-1" src={pfad141} alt="Pfad 141" />
      <img className="pfad-14-2" src={pfad142} alt="Pfad 142" />
      <img className="pfad-14-3" src={pfad143} alt="Pfad 143" />
      <img className="pfad-14-4" src={pfad144} alt="Pfad 144" />
      <img className="pfad-1-5" src={pfad145} alt="Pfad 145" />
    </div>
  );
}

export default Gruppe33;
