import React from "react";
import "./Gruppe29.css";

function Gruppe29(props) {
  const { pfad131, pfad132, pfad133, pfad134, pfad135, className } = props;

  return (
    <div className={`gruppe-29 ${className || ""}`}>
      <img className="pfad-13-1" src={pfad131} alt="Pfad 131" />
      <img className="pfad-13-2" src={pfad132} alt="Pfad 132" />
      <img className="pfad-13-3" src={pfad133} alt="Pfad 133" />
      <img className="pfad-13-4" src={pfad134} alt="Pfad 134" />
      <img className="pfad-1-4" src={pfad135} alt="Pfad 135" />
    </div>
  );
}

export default Gruppe29;
