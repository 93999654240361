import "./App.css";
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import BenutzerdefiniertesFormat1 from "./components/BenutzerdefiniertesFormat1";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:path(|benutzerdefiniertes-format-1)">
          <BenutzerdefiniertesFormat1 {...benutzerdefiniertesFormat1Data} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
const gruppe251Data = {
    pfad111: "/img/pfad-111@1x.png",
    pfad112: "/img/pfad-112@1x.png",
    pfad113: "/img/pfad-113@1x.png",
    pfad114: "/img/pfad-114@1x.png",
    pfad115: "/img/pfad-115@1x.png",
};

const gruppe252Data = {
    pfad111: "/img/pfad-116@1x.png",
    pfad112: "/img/pfad-117@1x.png",
    pfad113: "/img/pfad-118@1x.png",
    pfad114: "/img/pfad-119@1x.png",
    pfad115: "/img/pfad-120@1x.png",
    className: "gruppe-26",
};

const gruppe291Data = {
    pfad131: "/img/pfad-131@1x.png",
    pfad132: "/img/pfad-132@1x.png",
    pfad133: "/img/pfad-133@1x.png",
    pfad134: "/img/pfad-134@1x.png",
    pfad135: "/img/pfad-135@1x.png",
};

const gruppe292Data = {
    pfad131: "/img/pfad-136@1x.png",
    pfad132: "/img/pfad-137@1x.png",
    pfad133: "/img/pfad-138@1x.png",
    pfad134: "/img/pfad-139@1x.png",
    pfad135: "/img/pfad-140@1x.png",
    className: "gruppe-31",
};

const gruppe331Data = {
    pfad141: "/img/pfad-141@1x.png",
    pfad142: "/img/pfad-142@1x.png",
    pfad143: "/img/pfad-143@1x.png",
    pfad144: "/img/pfad-144@1x.png",
    pfad145: "/img/pfad-145@1x.png",
};

const gruppe332Data = {
    pfad141: "/img/pfad-146@1x.png",
    pfad142: "/img/pfad-147@1x.png",
    pfad143: "/img/pfad-148@1x.png",
    pfad144: "/img/pfad-149@1x.png",
    pfad145: "/img/pfad-150@1x.png",
    className: "gruppe-34",
};

const benutzerdefiniertesFormat1Data = {
    ellipse5: "/img/ellipse-5@1x.png",
    ellipse3: "/img/ellipse-3-1x-png@1x.png",
    telegram1: "/img/telegram-1x-png@1x.png",
    discord: "/img/discord-1x-png@1x.png",
    telegram2: "/img/telegram-1-1x-png@1x.png",
    about: "ABOUT",
    team: "TEAM",
    claim: "CLAIM",
    connect: "CONNECT",
    ellipse1: "/img/ellipse-1-1x-png@1x.png",
    ellipse2: "/img/ellipse-2-1x-png@1x.png",
    all1: "/img/all--1x-png-1x-png@1x.png",
    gruppe18: "/img/rechteck-16@1x.png",
    pfad1: "/img/pfad-1@1x.png",
    pfad2: "/img/pfad-2@1x.png",
    pfad3: "/img/pfad-3@1x.png",
    pfad4: "/img/pfad-4@1x.png",
    pfad5: "/img/pfad-5@1x.png",
    pfad6: "/img/pfad-6@1x.png",
    pfad7: "/img/pfad-7@1x.png",
    pfad8: "/img/pfad-8@1x.png",
    pfad9: "/img/pfad-9@1x.png",
    pfad10: "/img/pfad-10@1x.png",
    pfad11: "/img/pfad-11@1x.png",
    pfad12: "/img/pfad-12@1x.png",
    pfad13: "/img/pfad-13@1x.png",
    pfad14: "/img/pfad-14@1x.png",
    pfad15: "/img/pfad-15@1x.png",
    pfad16: "/img/pfad-16@1x.png",
    pfad17: "/img/pfad-17@1x.png",
    pfad18: "/img/pfad-18@1x.png",
    pfad19: "/img/pfad-19@1x.png",
    pfad20: "/img/pfad-20@1x.png",
    pfad21: "/img/pfad-21@1x.png",
    pfad22: "/img/pfad-22@1x.png",
    pfad23: "/img/pfad-23@1x.png",
    pfad24: "/img/pfad-24@1x.png",
    pfad25: "/img/pfad-25@1x.png",
    pfad26: "/img/pfad-26@1x.png",
    pfad27: "/img/pfad-27@1x.png",
    pfad28: "/img/pfad-28@1x.png",
    pfad29: "/img/pfad-29@1x.png",
    pfad30: "/img/pfad-30@1x.png",
    pfad31: "/img/pfad-31@1x.png",
    pfad32: "/img/pfad-32@1x.png",
    pfad33: "/img/pfad-33@1x.png",
    pfad34: "/img/pfad-34@1x.png",
    pfad35: "/img/pfad-35@1x.png",
    pfad36: "/img/pfad-36@1x.png",
    pfad37: "/img/pfad-37@1x.png",
    pfad38: "/img/pfad-38@1x.png",
    pfad39: "/img/pfad-39@1x.png",
    pfad40: "/img/pfad-40@1x.png",
    pfad41: "/img/pfad-41@1x.png",
    pfad42: "/img/pfad-42@1x.png",
    pfad43: "/img/pfad-43@1x.png",
    pfad44: "/img/pfad-44@1x.png",
    pfad45: "/img/pfad-45@1x.png",
    pfad46: "/img/pfad-46@1x.png",
    pfad47: "/img/pfad-47@1x.png",
    pfad48: "/img/pfad-48@1x.png",
    pfad49: "/img/pfad-49@1x.png",
    pfad50: "/img/pfad-50@1x.png",
    pfad51: "/img/pfad-51@1x.png",
    pfad52: "/img/pfad-52@1x.png",
    pfad53: "/img/pfad-53@1x.png",
    pfad54: "/img/pfad-54@1x.png",
    pfad55: "/img/pfad-55@1x.png",
    pfad56: "/img/pfad-56@1x.png",
    pfad57: "/img/pfad-57@1x.png",
    pfad58: "/img/pfad-58@1x.png",
    pfad59: "/img/pfad-59@1x.png",
    pfad60: "/img/pfad-60@1x.png",
    pfad61: "/img/pfad-61@1x.png",
    pfad62: "/img/pfad-62@1x.png",
    pfad63: "/img/pfad-63@1x.png",
    pfad64: "/img/pfad-64@1x.png",
    pfad65: "/img/pfad-65@1x.png",
    pfad66: "/img/pfad-66@1x.png",
    pfad67: "/img/pfad-67@1x.png",
    pfad68: "/img/pfad-68@1x.png",
    pfad69: "/img/pfad-69@1x.png",
    pfad70: "/img/pfad-70@1x.png",
    pfad71: "/img/pfad-71@1x.png",
    pfad72: "/img/pfad-72@1x.png",
    pfad73: "/img/pfad-73@1x.png",
    pfad74: "/img/pfad-74@1x.png",
    pfad75: "/img/pfad-75@1x.png",
    pfad76: "/img/pfad-76@1x.png",
    pfad77: "/img/pfad-77@1x.png",
    pfad78: "/img/pfad-78@1x.png",
    pfad79: "/img/pfad-79@1x.png",
    pfad80: "/img/pfad-80@1x.png",
    pfad81: "/img/pfad-81@1x.png",
    pfad82: "/img/pfad-82@1x.png",
    pfad83: "/img/pfad-83@1x.png",
    pfad84: "/img/pfad-84@1x.png",
    pfad85: "/img/pfad-85@1x.png",
    pfad86: "/img/pfad-86@1x.png",
    pfad87: "/img/pfad-87@1x.png",
    pfad88: "/img/pfad-88@1x.png",
    pfad89: "/img/pfad-89@1x.png",
    pfad90: "/img/pfad-90@1x.png",
    pfad91: "/img/pfad-91@1x.png",
    pfad92: "/img/pfad-92@1x.png",
    pfad93: "/img/pfad-93@1x.png",
    pfad94: "/img/pfad-94@1x.png",
    pfad95: "/img/pfad-95@1x.png",
    pfad96: "/img/pfad-96@1x.png",
    pfad97: "/img/pfad-97@1x.png",
    pfad98: "/img/pfad-98@1x.png",
    pfad99: "/img/pfad-99@1x.png",
    pfad100: "/img/pfad-100@1x.png",
    pfad101: "/img/pfad-101@1x.png",
    pfad102: "/img/pfad-102@1x.png",
    pfad103: "/img/pfad-103@1x.png",
    pfad104: "/img/pfad-104@1x.png",
    pfad105: "/img/pfad-105@1x.png",
    pfad106: "/img/pfad-106@1x.png",
    pfad107: "/img/pfad-107@1x.png",
    pfad108: "/img/pfad-108@1x.png",
    pfad109: "/img/pfad-109@1x.png",
    pfad110: "/img/pfad-110@1x.png",
    pfad121: "/img/pfad-121@1x.png",
    pfad122: "/img/pfad-122@1x.png",
    pfad123: "/img/pfad-123@1x.png",
    pfad124: "/img/pfad-124@1x.png",
    pfad125: "/img/pfad-125@1x.png",
    pfad126: "/img/pfad-126@1x.png",
    pfad127: "/img/pfad-127@1x.png",
    pfad128: "/img/pfad-128@1x.png",
    pfad129: "/img/pfad-129@1x.png",
    pfad130: "/img/pfad-130@1x.png",
    gruppe32: "/img/rechteck-17@1x.png",
    logoUpdated: "/img/logo-updated-1x-png@1x.png",
    place: "HOME",
    roadmap: "ROADMAP",
    howTo1: "HOW TO",
    instagram: "/img/instagram-1x-png@1x.png",
    gruppe48: "/img/pfad-179@1x.png",
    overlapGroup1: "/img/pfad-180@1x.png",
    pfad181: "/img/pfad-181@1x.png",
    pfad182: "/img/pfad-182@1x.png",
    pfad183: "/img/pfad-183@1x.png",
    pfad184: "/img/pfad-184@1x.png",
    pfad187: "/img/pfad-187@1x.png",
    overlapGroup: "/img/pfad-185@1x.png",
    pfad186: "/img/pfad-186@1x.png",
    pfad188: "/img/pfad-188@1x.png",
    pfad189: "/img/pfad-189@1x.png",
    pfad190: "/img/pfad-190@1x.png",
    pfad191: "/img/pfad-191@1x.png",
    pfad192: "/img/pfad-192@1x.png",
    pfad193: "/img/pfad-193@1x.png",
    pfad194: "/img/pfad-194@1x.png",
    gruppe46: "/img/pfad-195@1x.png",
    pfad196: "/img/pfad-196@1x.png",
    gruppe47: "/img/pfad-197@1x.png",
    pfad198: "/img/pfad-198@1x.png",
    pfad199: "/img/pfad-199@1x.png",
    pfad200: "/img/pfad-200@1x.png",
    pfad201: "/img/pfad-201@1x.png",
    pfad202: "/img/pfad-202@1x.png",
    pfad203: "/img/pfad-203@1x.png",
    pfad204: "/img/pfad-204@1x.png",
    pfad205: "/img/pfad-205@1x.png",
    pfad206: "/img/pfad-206@1x.png",
    pfad207: "/img/pfad-207@1x.png",
    gruppe52: "/img/pfad-208@1x.png",
    overlapGroup2: "/img/pfad-209@1x.png",
    pfad210: "/img/pfad-210@1x.png",
    pfad211: "/img/pfad-211@1x.png",
    pfad212: "/img/pfad-212@1x.png",
    pfad213: "/img/pfad-213@1x.png",
    pfad214: "/img/pfad-214@1x.png",
    pfad215: "/img/pfad-185@1x.png",
    pfad216: "/img/pfad-216@1x.png",
    pfad217: "/img/pfad-217@1x.png",
    pfad218: "/img/pfad-218@1x.png",
    pfad219: "/img/pfad-219@1x.png",
    pfad220: "/img/pfad-220@1x.png",
    pfad221: "/img/pfad-221@1x.png",
    pfad222: "/img/pfad-222@1x.png",
    pfad223: "/img/pfad-223@1x.png",
    pfad224: "/img/pfad-224@1x.png",
    gruppe50: "/img/pfad-225@1x.png",
    pfad226: "/img/pfad-226@1x.png",
    gruppe51: "/img/pfad-227@1x.png",
    pfad228: "/img/pfad-228@1x.png",
    pfad229: "/img/pfad-229@1x.png",
    pfad230: "/img/pfad-230@1x.png",
    pfad231: "/img/pfad-231@1x.png",
    pfad232: "/img/pfad-232@1x.png",
    pfad233: "/img/pfad-233@1x.png",
    pfad234: "/img/pfad-234@1x.png",
    pfad235: "/img/pfad-235@1x.png",
    pfad236: "/img/pfad-236@1x.png",
    gruppe53: "/img/pfad-237@1x.png",
    pfad238: "/img/pfad-238@1x.png",
    pfad239: "/img/pfad-239@1x.png",
    pfad240: "/img/pfad-240@1x.png",
    pfad241: "/img/pfad-241@1x.png",
    pfad242: "/img/pfad-242@1x.png",
    pfad243: "/img/pfad-243@1x.png",
    pfad244: "/img/pfad-244@1x.png",
    pfad245: "/img/pfad-245@1x.png",
    pfad246: "/img/pfad-246@1x.png",
    pfad247: "/img/pfad-247@1x.png",
    pfad248: "/img/pfad-248@1x.png",
    pfad249: "/img/pfad-249@1x.png",
    pfad250: "/img/pfad-250@1x.png",
    pfad251: "/img/pfad-251@1x.png",
    pfad252: "/img/pfad-252@1x.png",
    pfad253: "/img/pfad-253@1x.png",
    pfad254: "/img/pfad-254@1x.png",
    pfad255: "/img/pfad-255@1x.png",
    pfad256: "/img/pfad-256@1x.png",
    pfad257: "/img/pfad-257@1x.png",
    pfad258: "/img/pfad-258@1x.png",
    pfad259: "/img/pfad-259@1x.png",
    penguCombinesADef1: <React.Fragment>Pengu combines a deflationary meme coin<br />with a Play-2-Earn game based on NFTs.<br /><br />A part of every transaction that is done with Pengu, goes directly to WWF to help contributing for the fight to safe the environment of our Earth.</React.Fragment>,
    gruppe411: "/img/pfad-161-1@1x.png",
    pfad1621: "/img/pfad-162-1@1x.png",
    pfad1631: "/img/pfad-163-1@1x.png",
    pfad1641: "/img/pfad-164-1@1x.png",
    pfad1651: "/img/pfad-165-1@1x.png",
    pfad1661: "/img/pfad-166-1@1x.png",
    pfad1671: "/img/pfad-167-1@1x.png",
    pfad1681: "/img/pfad-168@1x.png",
    pfad1691: "/img/pfad-169-1@1x.png",
    pfad1701: "/img/pfad-170-1@1x.png",
    pfad1711: "/img/pfad-171-1@1x.png",
    pfad1721: "/img/pfad-172-1@1x.png",
    pfad1731: "/img/pfad-173-1@1x.png",
    pfad1741: "/img/pfad-174-1@1x.png",
    pfad1751: "/img/pfad-175-1@1x.png",
    pfad1761: "/img/pfad-176@1x.png",
    pfad1771: "/img/pfad-177@1x.png",
    gruppe412: "/img/pfad-161-1@1x.png",
    pfad1622: "/img/pfad-162-1@1x.png",
    pfad1632: "/img/pfad-163-1@1x.png",
    pfad1642: "/img/pfad-164-1@1x.png",
    pfad1652: "/img/pfad-165-1@1x.png",
    pfad1662: "/img/pfad-166-1@1x.png",
    pfad1672: "/img/pfad-167-1@1x.png",
    pfad1682: "/img/pfad-168-1@1x.png",
    pfad1692: "/img/pfad-169-1@1x.png",
    pfad1702: "/img/pfad-170-1@1x.png",
    pfad1712: "/img/pfad-171-1@1x.png",
    pfad1722: "/img/pfad-172-1@1x.png",
    pfad1732: "/img/pfad-173-1@1x.png",
    pfad1742: "/img/pfad-174-1@1x.png",
    pfad1752: "/img/pfad-175-1@1x.png",
    pfad1762: "/img/pfad-176-1@1x.png",
    pfad1772: "/img/pfad-177-1@1x.png",
    buy: "BUY",
    howTo2: "HOW TO",
    ellipse4: "/img/ellipse-4@1x.png",
    bgPengu2Back: "/img/bg-pengu-2back@1x.png",
    bgPengu2Aurora: "/img/bg-pengu-2-aurora--1x-png@1x.png",
    penguCombinesADef2: <React.Fragment>Pengu combines a deflationary meme coin with a Play-2-Earn game based on NFTs. A part of every transaction that is done with Pengu, goes directly to WWF to help contributing for the fight to safe the environment of our Earth.<br /><br />Pengu combines a deflationary meme coin<br />with a Play-2-Earn game based on NFTs.<br /><br />A part of every transaction that is done with Pengu, goes directly to WWF to help contributing for the fight to safe the environment of our Earth.</React.Fragment>,
    uI48c: "",
    theVisionOfPengu: "THE VISION OF PENGU",
    x015OfTheTransac1: <React.Fragment>0.15% of the transaction fee is distributed to all holders of Pengu Tokens.<br /><br />It will reward community members who are holding Pengu in their wallets.</React.Fragment>,
    x015OfTheTransac2: <React.Fragment>0.15% of the transaction fee goes into the Pengumon reward pool.<br /><br />Rewards of the pool will be distributed through Pengumon Expeditions.</React.Fragment>,
    x010OfTheTransac1: <React.Fragment>0.10% of the transaction fee is getting burned forever.<br /><br />It will decrease the available supply of Pengu over time.</React.Fragment>,
    x010OfTheTransac2: <React.Fragment>0.10% of the transaction fee goes to a wallet that is owned by a worldwide acting charity organization, as a donation.<br /><br />This will help to fight for the protection of the environment and wildlife of our Earth.</React.Fragment>,
    igloo2Back: "/img/igloo-2-back-1x-png@1x.png",
    igloo2Rocket: "/img/igloo-2-rocket-1x-png@1x.png",
    igloo2Front: "/img/igloo-2-front-1x-png@1x.png",
    igloo1: "/img/igloo-1-1x-png@1x.png",
    igloo1Glow: "/img/igloo-1-glow-1x-png@1x.png",
    igloo2Fire: "/img/igloo-2-fire-1x-png@1x.png",
    igloo2Sign: "/img/igloo-2-sign-1x-png@1x.png",
    igloo2Glow: "/img/igloo-2-glow-1x-png@1x.png",
    all2: "/img/all-1x-png@1x.png",
    bgPengu3Bg: "/img/bg-pengu-3-bg-1x-png-1x-png@1x.png",
    bgPengu3Iceberg1: "/img/bg-pengu-3-iceberg-1@1x.png",
    bgPengu3Iceberg11: "/img/bg-pengu-3-iceberg-1-1@1x.png",
    bgPengu3Iceberg2: "/img/bg-pengu-3-iceberg-2@1x.png",
    bgPengu3Iceberg21: "/img/bg-pengu-3-iceberg-2-1@1x.png",
    bgPengu3Iceberg3: "/img/bg-pengu-3-iceberg-3@1x.png",
    bgPengu3Iceberg4: "/img/bg-pengu-3-iceberg-4@1x.png",
    bgPengu3Iceberg5: "/img/bg-pengu-3-iceberg-5@1x.png",
    bgPengu3Iceberg51: "/img/bg-pengu-3-iceberg-5-1@1x.png",
    bgPengu3Iceberg52: "/img/bg-pengu-3-iceberg-5-2@1x.png",
    bgPengu3Iceberg6: "/img/bg-pengu-3-iceberg-6@1x.png",
    bgPengu3Iceberg61: "/img/bg-pengu-3-iceberg-6-1@1x.png",
    gruppe251Props: gruppe251Data,
    gruppe252Props: gruppe252Data,
    gruppe291Props: gruppe291Data,
    gruppe292Props: gruppe292Data,
    gruppe331Props: gruppe331Data,
    gruppe332Props: gruppe332Data,
};

